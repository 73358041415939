* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    scrollbar-width: thin;
}

ul,
li,
p,
a {
    padding: 0px;
    margin: 0;
}


/* this is useful for dateRangePicker please do not change it */

.rs-picker-toggle-wrapper {
    color: white !important;
    display: block !important;
}

.rs-picker-toggle-textbox {
    background-color: rgb(0, 4, 24) !important;
    color: white !important;
}

.rs-picker-daterange-panel {
    background-color: rgb(0, 4, 24) !important;
    color: white !important;
}

.rs-anim-fade.rs-anim-in {
    background-color: rgb(0, 4, 24) !important;
    color: white !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    background-color: transparent !important;
    color: white !important;
    border-color: rgba(165, 165, 165, 0.397) !important;
}

.rs-picker-toggle-read-only {
    color: white !important;
}

.rs-picker-toggle-value {
    color: white !important;
}

::-webkit-scrollbar {
    width: 0.5em;
}


::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100vw;
    margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    border: .25em solid rgb(0, 4, 24);
    background: rgb(0, 4, 24);
}

::-webkit-scrollbar
{
  height: 5px; /* for horizontal scrollbars */
}

.rs-table-loader-wrapper {
    background-color: rgba(32, 36, 46, 0.74) !important;
}

.rs-table-loader {}

.rs-table-affix-header{
}


/* 
.rs-table-row-expanded {
    background-color: #3D4552 !important;
    padding: 0 !important;
    min-width: 100% !important;
    width: 100% !important;
    overflow-y: scroll !important;
} */


/* 
.Table_table__Fcyxi {
    min-width: unset !important;
} */

form .MuiFormControl-root {
    margin-left: 0 !important;
}

.MuiOutlinedInput-input {
    color:white !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
    color: white;
    border-color:white !important;
}